<script setup>
import { onMounted, ref } from 'vue';
import { auth_sso } from '@/services/auth'

const noParam = ref(false)
onMounted(async()=>{
  const queryString = window.location.search;
  console.log(queryString);
  const urlParams = new URLSearchParams(queryString);
  let credential = urlParams.get('credential')
  const sso = urlParams.get('sso')
  const sig = urlParams.get('sig')
  if(credential){
    localStorage.setItem('credential', credential)
    let forum_app =  process.env.VUE_APP_FORUM_HOST + '/session/sso'  || 'http://localhost:3000/session/sso'
    window.location.replace(forum_app)
    return
  }
  if (sso && sig){
    credential = localStorage.getItem('credential')
    if (credential){
      let response = await auth_sso({sso, sig, credential})
      // console.log(response)
      let params_redirect = `sso=${response.result.sso.sso}&sig=${response.result.sso.sig}`
      window.location.replace(response.result.sso.redirect_url+'?'+params_redirect)
      return
    }
    // return
  }
  noParam.value = true
  // set as local storage for key
  // redirect to forum app to get sig sso key
})
</script>

<template>
  <div class="home">
    <p v-if="noParam">Please login to your account first</p>
    <p v-else>Authenticating..</p>
  </div>
</template>