import axios from 'axios'
import router from '@/router'

// instance
const formRequest = axios.create({
  baseURL: process.env.VUE_APP_FORUM_HOST,
  timeout: 5000,
  headers: {'Access-Control-Allow-Origin': '*'}
});

const authRequest = axios.create({
  baseURL: process.env.VUE_APP_API_HOST,
  timeout: 5000,
  headers: {'Authorization': localStorage.getItem('uid')}
});

const plainRequest = axios.create({
  baseURL: process.env.VUE_APP_API_HOST,
  timeout: 5000,
});

const authInterceptor = (config) => {
  config.headers.Authorization = localStorage.getItem('uid')
  return config
}

authRequest.interceptors.request.use(authInterceptor)
authRequest.interceptors.response.use(function (response) {
  return response;
}, function (error) {
  let name = '';
  switch (error.response.status) {
    case 401: 
      name = 'login';
      localStorage.removeItem('uid')
      localStorage.removeItem('user_data')
    break;
  }
  router.push({name})
  return Promise.reject(error);
})

export {authRequest, plainRequest, formRequest};