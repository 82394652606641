import {authRequest} from './index.js'


export async function auth_sso (formData, sign_in){
  let data
  await authRequest.post(
    '/sso', 
    {user: formData, sign_in}
  ).then(function(result){
    data = {result: result.data, error: false}
  }).catch(function (error){
    console.log('error',error)
    data = {error: true, result: error.response.data}
  })
  return data
}

export async function redirect_sso (){
  let data
  await authRequest.get(
    '/auth_sso'
  ).then(function(result){
    data = {result: result.data, error: false}
  }).catch(function (error){
    console.log('error',error)
    data = {error: true, result: error.response.data}
  })
  return data
}